import React from "react";
import { useIntl } from "react-intl";
import PropTypes from 'prop-types';

import { reportsModelingService } from "../../../services/reports-modeling-service";

import { DropdownSearchHelpInput } from "../../common/form-controls";

const getRoles = (params, signal) => {
    return reportsModelingService.getRolesModelingSearchHelp(params, signal);
};

const RolesInput = ({ ids, setIds}) => {
    const intl = useIntl();
    const label = intl.formatMessage({ id: "report-parameters.roles-label" });

    return (
        <DropdownSearchHelpInput
            ids={ids}
            setIds={setIds}
            getData={getRoles}
            label={label}
            disableMultiple={true}
        />
    );
};

RolesInput.propTypes = {
    ids: PropTypes.arrayOf(PropTypes.string),
    setIds: PropTypes.func
};

export default RolesInput;
