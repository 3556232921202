import LOCALES from "./locales";
import { REPORT_MODELING_CONFLICT_LEVELS, REPORT_MODELING_CONFLICT_LEVELS_USER, REPORT_MODELING_STATUSES, REPORT_MODELING_SUBVIEWS } from "../utils/reports-modeling-utils";

export default {
    [LOCALES.RUSSIAN]: {
        "common-toolbar.title": "ASOD",
        "common-start-page": "Добро пожаловать в ASOD!",

        "common-toolbar-main-menu.title": "Главное меню",
        "common-toolbar-sod-matrix.title": "Ведение SoD-матрицы",
        "common-toolbar-sod-reports.title": "Отчёты SoD",
        "common-toolbar-reports-modeling.title": "Моделируемые отчеты",
        "common-toolbar-sod-reports-user-level": "Уровень пользователя",
        "common-toolbar-sod-reports-role-level": "Уровень роли",
        "common-toolbar-sod-reports-custom-roles":"Ведение кастомных ролей", 
        "common-toolbar-settings.title": "Настройки",
        "common-toolbar-sod-matrix.access-rules.title": "Ведение правил доступа",
        "common-toolbar-sod-matrix.critical-access-rules.title": "Правила критического доступа",
        "common-toolbar-settings-integration.title": "Интеграции",
        "common-toolbar-settings-systems.title": "Системы",
        "common-toolbar-settings-riskLevels.title": "Уровни рисков",
        "common-toolbar-users.title": "Пользователи",

        "common-toolbar.brm.admin.title": "Администрирование",
        "common-toolbar.brm.my-tasks.title": "Мои задачи",

        "common.pagination.limit": "Отображать по",
        "common.table.filter-menu.add": "Добавить фильтр",
        "common.table.filter-menu.reset": "Сбросить фильтр",
        "common.table.filter-menu.sort": "Сортировать",
        "common.table.filter-menu.reset-sort": "Сбросить сортировку",
        "common.table.filter-select-dialog.reset": "Сбросить все фильтры",
        "common.table.active.true": "Активно",
        "common.table.active.false": "Неактивно",
        "common.search-input.title": "Поиск",
        "common.date-range-input.title": "Выбрать период",
        "common.btn-save": "Сохранить",
        "common.btn-cancel": "Отменить",
        "common.btn-save-and-exit": "Сохранить и закрыть",
        "common.btn-apply": "Применить",
        "common.btn-close": "Закрыть",
        "common.btn-edit": "Редактировать",
        "common.btn-change": "Изменить",
        "common.btn-delete": "Удалить",
        "common.btn-copy": "Копировать",
        "common.btn-mass-delete": "Массовое удаление",
        "common.btn-analyze": "Выполнить анализ",
        "common.btn-calc-report-manual": "Выполнить анализ вручную",
        "common.btn-calc-report-bgnd": "Настройка фонового расчета",
        "common.btn-show-variants": "Показать шаблоны",
        "common.btn-save-template": "Сохранить как свой шаблон",
        "common.btn-sync": "Значения по умолчанию",
        "common.btn-logs": "Показать историю изменений",
        "common.btn-add": "Добавить",
        "common.btn-sync-with-sap": "Синхронизировать с SAP",
        "common.btn-column-settings": "Настройка колонок",
        "common.submit-dialog.btn-apply": "Применить",
        "common.submit-dialog.btn-close": "Закрыть",
        "common.delete-dialog.btn-delete": "Удалить",
        "common.delete-confirm-dialog.input-label": 'Для продолжения введите "Удалить"',
        "common.delete-confirm-dialog.correct-input-value": 'Удалить',
        "common.delete-confirm-dialog.input-error": 'Вы ввели неверное слово!',
        "common.progress-dialog.save.title": "Сохранение",
        "common.progress-dialog.load.title": "Загрузка",
        "common.error.title": "Ошибка операции",
        "common.reset-filters-title": "Сбросить все фильтры",
        "common.about-app-title": "О приложении",
        "commom.app-version-dialog.core-version-text": `Версия модуля CORE: v{coreVersion}`,
        "commom.app-version-dialog.sod-version-text": `Версия модуля SOD: v{sodVersion}`,
        "common.user-roles.admin": "Администратор",
        "common.user-roles.employee": "Обычный пользователь",
        "common.user-roles.consultant": "Консультант",
        "common.user-roles.all": "Все",
        "common.user-log-dialog.title": "История изменения",
        "common.compare-report-dialog.title": "Сравнение отчетов",

        "sign-in-form.title": "Вход в систему ASOD",
        "sign-in-form.username": "Имя пользователя",
        "sign-in-form.password": "Пароль",
        "sign-in-form.btn-sign-in": "Войти",
        "sign-in-form.auth-error": "При входе в систему произошла ошибка. Проверьте имя пользователя и пароль.",
        "user-menu.btn-sign-out": "Выйти",

        "admin-settings-page.btn-save-settings": "Сохранить настройки",
        "admin-settings-page.btn-logs": "История изменений",
        "admin-settings-page.core-settings.title": "Ведение настроек CORE модуля",
        "admin-settings-page.sod-settings.title": "Ведение настроек SOD модуля",
        "admin-settings-page.success-save": "Настройки успешно сохранены",

        "search-help.choose-resource.title": "Выбор ресурса",
        "search-help.choose-critical-role.title": "Выбор роли",
        "search-help.choose-critical-profile.title": "Выбор профиля",
        "search-help.columns.description": "Описание",
        "search-help.columns.is_system_group": "Группа систем",
        "search-help.columns.critical-role": "Имя роли",
        "search-help.columns.critical-profile": "Имя профиля",
        "search-help.columns.system": "Система",
        "search-help.columns.busyness-process": "Бизнес процесс",
        "search-help.columns.risk": "Риск",
        "search-help.columns.matrix": "Матрица",
        "search-help.columns.users": "Пользователи",
        "search-help.columns.users-fullname": "ФИО",
        "search-help.columns.roles": "Роль",
        "search-help.tag-view.title": "Выбранные элементы",
        "search-help.filters.funcrion-id.title": "Фильтры функции",
        "search-help.filters.matrix-id.title": "Фильтры матрицы",
        "search-help.filters.risk-id.title": "Фильтры риска",
        "search-help.filters.system-id.title": "Фильтры системы",
        "search-help.filters.system-group-id.title": "Фильтры группы систем",
        "search-help.filters.risk-level-id.title": "Фильтры уровня риска",
        "search-help.filters.username.title": "Фильтры пользователя",
        "search-help.filters.crit-role-name.title": "Фильтры критической роли",
        "search-help.filters.crit-profile-name.title": "Фильтры критического профиля",
        "search-help.filters.description.title": "Фильтры описания",
        "search-help.filters.active.title": "Фильтры статуса",
        "search-help.filters.firstname.title": "Фильтры имени",
        "search-help.filters.lastname.title": "Фильтры фамилии",
        "search-help.filters.middlename.title": "Фильтры отчества",
        "search-help.filters.phone.title": "Фильтры телефона",
        "search-help.filters.email.title": "Фильтры email",
        "search-help.filters.default.title": "Фильтры",

        "access-rules-page.tabs.function-maintenance.title": "Ведение функций",
        "access-rules-page.tabs.matrix-header-maintenance.title": "Ведение матриц",
        "access-rules-page.tabs.risk-maintenance.title": "Ведение рисков",
        "critical-rules-page.tabs.crit-roles.title": "Критические роли",
        "critical-rules-page.tabs.crit-profiles.title": "Критические профили",

        "report-results.accordion-title": "Результаты анализа",
        "report-results.export-title": "Export",
        "report-results.tabs.operations-level": "Уровень операций",
        "report-results.tabs.permissions-level": "Уровень разрешений",
        "report-results.tabs.critical-operations-level": "Уровень критических операций",
        "report-results.tabs.critical-permissions-level": "Уровень критических разрешений",
        "report-results.tabs.critical-objects-level": "Уровень критических объектов",
        "report-results.table.lower-risk-button": "Снизить риск",
        "report-results.report-types.final": "Итоговый отчет",
        "report-results.report-types.detailed": "Подробная информация",

        "user-level-report-page.title": 'Отчёт "Уровень пользователя"',
        "user-level-report-page.variant-selected.title": 'Вариант "{name}"',
        "user-level-report-page.delete-dialog.title": "Удалить шаблон",
        "user-level-report-page.delete-dialog.text": `Шаблон {name} будет удален. Желаете продолжить?`,

        "user-level-report-page.favorites-dialog.add.title": "Добавить в избранное",
        "user-level-report-page.favorites-dialog.add.btn-add": "Добавить",
        "user-level-report-page.favorites-dialog.add.text": `Шаблон {name} будет добавлен в избранное и отражаться в навбаре. Желаете продолжить?`,

        "user-level-report-page.favorites-dialog.remove.title": "Убрать из избранного",
        "user-level-report-page.favorites-dialog.remove.btn-remove": "Убрать",
        "user-level-report-page.favorites-dialog.remove.text": `Шаблон {name} будет убран из избранных. Желаете продолжить?`,

        "role-level-report-page.title": 'Отчет "Уровень роли"',

        "save-variant-dialog.title": "Сохранить шаблон",
        "save-variant-dialog.input.variant-name": "Введите название шаблона",

        "show-variants-dialog.title": "Список шаблонов",
        "show-variants-dialog.btn-apply-template": "Применить шаблон",

        "report-parameters.show-parameters": "Критерии анализа",
        "report-parameters.roles-label": "Имя роли",
        "report-parameters.systems-label": "Система",
        "report-parameters.matrix-label": "Матрица",
        "report-parameters.risks-label": "Идентификатор риска",
        "report-parameters.risk-level-label": "Уровень риска",
        "report-parameters.users-label": "Пользователь",
        "report-parameters.online-sync": "Загрузить онлайн",

        "funtions-view.toolbar.add-function.text": "Добавить функцию",

        "function-list-page.toolbar.text.title": "Список функций",
        "function-list-page.toolbar.btn-create-func": "Создать функцию",
        "function-list-page.toolbar.btn-edit-func": "Редактировать функцию",

        "function-form-page-header.new.title": "Добавление функции",
        "function-form-page-header.edit.title": "Редактирование функции {functionId}",
        "function-form-page-header.read.title": "Просмотр функции {functionId}",
        "function-form-page-header.btn-delete-function": "Удалить функцию",

        "function-form-page-function.title": "Ведение функции",
        "function-form-page-function.input-function-id": "Введите идентификатор функции",
        "function-form-page-function.input-scope-of-analysis": "Выберите объем анализа",
        "function-form-page-function.input-scope-of-analysis.one-system": "Отдельная система",
        "function-form-page-function.input-scope-of-analysis.many-systems": "Множество систем",
        "function-form-page-function.input-business-process": "Выберите бизнес-процесс",
        "function-form-page-function.input-business-process.process1": "Бизнес-процесс 1",
        "function-form-page-function.input-business-process.process2": "Бизнес-процесс 2",
        "function-form-page-function.input-business-process.process-null": " ",
        "function-form-page-function.input-description": "Введите описание функции",
        "function-form-page-function.input-system": "Выберите систему или группу систем",
        "function-form-page-function.input-busyness-process": "Выберите бизнес процесс",
        "function-form-page-function.tabs.operations": "Операции",
        "function-form-page-function.tabs.permissions": "Разрешения",

        "function-form-page.delete-dialog.title": "Удаление функции",
        "function-form-page.delete-function.text": `Функция {name} будет удалена. Желаете продолжить?`,

        "function-form-page.subtable-column.system": "Система",
        "function-form-page.subtable-column.permissionGroup": "Группа разрешений",
        "function-form-page.subtable-column.permission": "Разрешение",
        "function-form-page.subtable-column.field": "Поле",
        "function-form-page.subtable-column.valueFrom": "Значение С",
        "function-form-page.subtable-column.valueTo": "Значение ПО",
        "function-form-page.subtable-column.active": "Статус",
        "function-form-page.subtable-column.description": "Описание",
        "function-form-page.subtable-column.operation": "Операции",

        "function-form-page.subtable-column.objectType": "Тип объекта",
        "function-form-page.subtable-column.objectName": "Наименование объекта",
        "function-form-page.subtable-column.value": "Значение",
        "function-form-page.subtable-column.condition": "Условие",

        "operation-view.btn-create-operation": "Создать операцию",

        "permission-view.btn-add-permission": "Добавить разрешение",
        "permission-view.sign-items.or": "ИЛИ",
        "permission-view.sign-items.and": "И",
        "permission-view.sign-items.not": "НЕ",
        "permission-view.mass-sign": "Изменить знак",
        "permission-view.mass-status": "Изменить статус",
        "permission-view.sync-dialog.title": "Синхронизация разрешений",

        "risk-list-page.toolbar.text.title": "Список рисков",
        "risk-list-page.toolbar.btn-create-risk": "Создать риск",

        "risk-form-page-header.new.title": "Добавление риска",
        "risk-form-page-header.edit.title": "Редактирование риска {riskId}",
        "risk-form-page-header.read.title": "Просмотр риска {riskId}",
        "risk-form-page-header.btn-delete-risk": "Удалить риск",

        "risk-form-page-risk.title": "Ведение риска",
        "risk-form-page-risk.input-risk-id": "Введите идентификатор риска",
        "risk-form-page-risk.input-risk-lvl": "Выберите уровень риска",
        "risk-form-page.input-risk-type": "Выберите тип риска",
        "risk-form-page.input-status": "Выберите статус",
        "risk-form-page-risk.input-description": "Введите описание риска",
        "risk-form-page-risk.input-functions": "Выберите функции",
        "risk-form-page-risk.input-matrix-headers": "Введите идентификатор матрицы",

        "risk-form-page-risk.risk-types.common": "Разделение полномочий",
        "risk-form-page-risk.risk-types.crit_action": "Критичные операции",
        "risk-form-page-risk.risk-types.crit_permission": "Критичные разрешения",


        "risk-list-page.delete-dialog.title": "Удаление риска",
        "risk-list-page.delete-risk.text": `Риск {name} будет удален. Желаете продолжить?`,
        "role-list-page.toolbar.text.title": "Список ролей",
        "role-list-page.toolbar.btn-create-role": "Создать роль",

        "role-form-page-header.new.title": "Добавление критической роли",
        "role-form-page-header.edit.title": "Редактирование критической роли {name}",
        "role-form-page-header.read.title": "Просмотр критической роли {name}",
        "role-form-page-header.btn-delete-role": "Удалить роль",

        "role-form-page-role.title": "Ведение критической роли",
        "role-form-page-role.input-system": "Выберите систему",
        "role-form-page-role.input-role-name": "Введите название критической роли",
        "role-form-page-role.input-status": "Выберите статус",
        "role-form-page-role.input-description": "Введите описание критической роли",

        "role-form-page.delete-dialog.title": "Удаление критической роли",
        "role-form-page.delete-role.text": `Критическая роль {name} будет удалена. Желаете продолжить?`,

        "profile-list-page.toolbar.text.title": "Список профилей",
        "profile-list-page.toolbar.btn-create-profile": "Создать профиль",

        "profile-form-page-header.new.title": "Добавление критического профиля",
        "profile-form-page-header.edit.title": "Редактирование критического профиля {name}",
        "profile-form-page-header.read.title": "Просмотр критического профиля {name}",
        "profile-form-page-header.btn-delete-profile": "Удалить профиль",

        "profile-form-page-profile.title": "Ведение критического профиля",
        "profile-form-page-profile.input-profile-name": "Введите название критического профиля",
        "profile-form-page-profile.input-description": "Введите описание критического профиля",

        "profile-form-page.delete-dialog.title": "Удаление критического профиля",
        "profile-form-page.delete-profile.text": `Критический профиль {name} будет удален. Желаете продолжить?`,

        "critical-objects.input-status.active": "Активно",
        "critical-objects.input-status.inactive": "Неактивно",

        "transaction-settings-page.tabs.systems.title": "Системы",
        "transaction-settings-page.tabs.system-groups.title": "Группы систем",
        "transaction-settings-page.tabs.risk-levels.title": "Уровни рисков",

        "integration-settings-page.toolbar.text": "Загрузка интеграционных данных",
        "integration-settings-page.status.success": "Успешно",
        "integration-settings-page.status.warning": "С предупреждениями",
        "integration-settings-page.status.error": "Ошибка",
        "integration-settings-page.status.pending": "В процессе",
        "integration-settings-page.status.connection_lost": "Соединение разорвано",

        "integration-system-box.btn-sheduled-sync": "Запланированные синх.",
        "integration-system-box.checkbox-permission-details": "Детализация полномочий",
        "integration-system-box.sync-status.common.true": "Синхронизировано",
        "integration-system-box.sync-status.common.error": "Ошибка синхронизации",
        "integration-system-box.sync-status.common.pending": "Синхронизация запущена",
        "integration-system-box.sync-status.common.false": "Не синхронизировано",
        "integration-system-box.sync-status.common.connection_lost": "Соединение разорвано",
        "integration-system-box.sync-status.entity.true": "Последняя синхронизация:",
        "integration-system-box.sync-status.entity.error": "Ошибка синхронизации",
        "integration-system-box.sync-status.entity.pending": "Синхронизация запущена",
        "integration-system-box.sync-status.entity.false": "Не синхронизировано",
        "integration-system-box.sync-status.entity.connection_lost": "Соединение разорвано",
        "integration-system-box.sync-status.manual.true": "Ручная загрузка матрицы проведена",
        "integration-system-box.sync-status.manual.error": "Ошибка ручной загрузки матрицы",
        "integration-system-box.sync-status.manual.pending": "Ручная загрузка матрицы запущена",
        "integration-system-box.sync-status.manual.connection_lost": "Соединение разорвано",

        "integration.actions-box.checkbox-choose-all": "Выбрать все",
        "integration.actions-box.btn-set-up-sync-scheduling": "Настроить планирование синхр.",
        "integration.actions-box.btn-run-sync": "Запустить синхр. данных",

        "integration-details-row.roles.text": "Роли",
        "integration-details-row.users.text": "Пользователи",
        "integration-details-row.profiles.text": "Профили",
        "integration-details-row.authority-detail.text": "Cущности доступа 1С",
        "integration-details-row.metadata-object.text": "Объекты метаданных",
        "integration-details-row.manual-integration.text": "Ручная загрузка матрицы",
        "integration-details-row.btn-sheduled": "Запланирован в...",
        "integration-details-row.btn-reset-status": "Сбросить статус",
        "integration-details-row.btn-upload-matrix": "Ручная загрузка матрицы",
        "integration-details-row.load-delta.text": "Загрузка дельты",
        "integration-details-row.sync-future.text": "Синх. будущих присвоений",

        "integration-history-dialog.columns.entity-text": "Поток",
        "integration-history-dialog.columns.status-text": "Статус",
        "integration-history-dialog.columns.start-date": "Дата начала",

        "schedule-maintenance-dialog.columns.name": "Имя планирования",
        "schedule-maintenance-dialog.columns.startDate": "Дата начала",
        "schedule-maintenance-dialog.columns.typeText": "Тип",

        "schedule-maintenance-dialog.schedule-type.common": "Полная синхронизация",
        "schedule-maintenance-dialog.schedule-type.repository_objects": "Объекты репозитория",
        "schedule-maintenance-dialog.schedule-type.permission_details": "Детализация полномочий",

        "systems-list-page.toolbar.text.title": "Список интегрированных систем",
        "systems-list-page.toolbar.btn-create-system": "Добавить систему",

        "empty-systems-page.text": "На данный момент у вас нет ни одной привязанной системы. Создайте систему и произведите интеграцию.",

        "systems-list-page.btn-create-system": "Создать систему",

        "system-form-page.input-integration-type.http": "HTTP",
        "system-form-page.input-integration-type.rfc": "RFC",
        "system-form-page.input-system-type.sap": "SAP",
        "system-form-page.input-system-type.1c": "1C",

        "system-form-page-header.new.title": "Добавление системы",
        "system-form-page-header.edit.title": "Редактирование системы {systemId}",
        "system-form-page-header.read.title": "Просмотр системы {systemId}",
        "system-form-page-header.btn-delete-system": "Удалить систему",

        "system-form-page-system.title": "Ведение системы",
        "system-form-page-system.input-system-id": "Введите идентификатор системы ASOD",
        "system-form-page-system.input-system-type": "Выберите тип системы",
        "system-form-page-system.input-description": "Описание",

        "system-form-page-auth.title": "Ведение авторизационных данных системы",
        "system-form-page-auth.input-integration-type": "Тип интеграции",
        "system-form-page-auth.input-host": "Сервер приложений",
        "system-form-page-auth.input-instance-number": "Номер инстанции",
        "system-form-page-auth.input-sap-system-id": "ИдСистемы",
        "system-form-page-auth.input-saprouter": "Стринг SAProuter",
        "system-form-page-auth.input-mandt": "Мандант",
        "system-form-page-auth.input-login": "Логин",
        "system-form-page-auth.input-url": "URL",
        "system-form-page-auth.btn-run-test-connection": "Запустить тестирование",
        "system-form-page-auth.test-status-pending.text": "Тест соединения",
        "system-form-page-auth.test-status-success.text": "Успешно",
        "system-form-page-auth.test-status-error.text": "Ошибка соединения",

        "delete-system-dialog.title": "Удаление системы",
        "delete-system-dialog.text": "Система {name} будет удалена. Желаете продолжить?",

        "system-groups-tab.empty-system-groups.can-create-text": "На данный момент вы не создали ни одной группы систем.",
        "system-groups-tab.empty-system-groups.can-not-create-text": "На данный момент вы не можете создать группу систем, так как у вас нет ни одной привязанной системы. Перейдите во вкладку “Системы” и создайте первую систему.",
        "system-groups-tab.empty-system-groups.btn-create-system-group": "Создать группу систем",
        "system-groups-tab.toolbar.text.title": "Список групп систем",
        "system-groups-tab.toolbar.btn-create-system-group": "Добавить группу систем",

        "group-form-page-header.new.title": "Добавление группы",
        "group-form-page-header.edit.title": "Редактирование группы {groupId}",
        "group-form-page-header.read.title": "Просмотр группы {groupId}",
        "group-form-page-header.btn-delete-system-group": "Удалить группу систем",

        "group-form-page-group.title": "Ведение групп систем",
        "group-form-page-group.delete-group-dialog.text": `Группа {name} будет удалена. Желаете продолжить?`,
        "group-form-page-group.input-group-id": "Введите идентификатор группы",
        "group-form-page-group.input-systems-ids": "Введите идентификаторы присвоенных систем",
        "group-form-page-group.input-systems-list": "Выберите системы из списка",
        "group-form-page-group.input-description": "Введите описание группы систем",

        "system-groups-tab.show-systems-list.text": "Показать список входящих систем",
        "system-groups-tab.hide-systems-list.text": "Скрыть список входящих систем",
        "system-groups-page.delete-dialog.title": "Удаление группы систем",

        "risk-levels-tab.empty-risk-levels.text": "На данный момент у вас нет ни одного созданного уровня риска.",
        "risk-levels-tab.toolbar.btn-create-risk-level": "Создать уровень риска",
        "risk-levels-tab.btn-reset": "Сбросить изменения",
        "risk-levels-tab.btn-create-risk-level": "Добавить уровень риска",
        "risk-levels-tab.errors.unique-description": "Описание уровня риска должно быть уникальным",
        "risk-levels-tab.errors.empty-description": "Описание уровня риска должно быть заполнено",
        "risk-level-input.description.text": "Введите описание уровня риска",

        "color-input.text": "Цвета по умолчанию",
        "color-input.btn-choose-color": "Выбрать свой цвет",

        "matrix-headers-list-page.columns.id": "Ид. матрицы",
        "matrix-headers-list-page.columns.description": "Описание",
        "matrix-headers-list-page.columns.updatedTime": "Последнее обновление",
        "matrix-headers-list-page.columns.updatedUser": "Обновил",
        "matrix-headers-list-page.columns.actions": "Действия",
        "matrix-headers-list-page.toolbarActions.title": "Список матриц",
        "matrix-headers-list-page.toolbarActions.add": "Создать матрицу",

        "matrix-headers-form-header.view-mode-title": "Просмотр матрицы {matrixHeaderId}",
        "matrix-headers-form-header.add-mode-title": "Создание матрицы",
        "matrix-headers-form-header.edit-mode-title": "Редактирование матрицы {matrixHeaderId}",
        "matrix-headers-form-header.cancel-button-text": "Отменить",
        "matrix-headers-form-header.edit-button-text": "Редактировать",
        "matrix-headers-form-header.delete-button-text": "Удалить",
        "matrix-headers-form-header.save-button-text": "Сохранить",
        "matrix-headers-form-content.title": "Введение матрицы",
        "matrix-headers-form-content.id-input-label": "Введите идентификатор набора правил",
        "matrix-headers-form-content.description-input-label": "Введите описание набора правил",
        "matrix-headers-form-content.risk-ids-input-label": "Выберите риск",
        "matrix-headers-form-content.critical-role-ids-input-label": "Выберите критические роли",
        "matrix-headers-form-content.critical-profile-ids-input-label": "Выберите критические профили",
        "matrix-headers-form-content.tabs.risk": "Список рисков",
        "matrix-headers-form-content.tabs.critical-roles": "Критические роли",
        "matrix-headers-form-content.tabs.critical-profiles": "Критические профили",

        "matrix-headers-form-content.subtable-column.risks.risk": "Ид. риска",
        "matrix-headers-form-content.subtable-column.risks.description": "Описание риска",

        "matrix-headers-form-content.subtable-column.active": "Статус",

        "matrix-headers-form-content.subtable-column.criticalRoles.system": "Система",
        "matrix-headers-form-content.subtable-column.criticalRoles.criticalRole": "Имя роли",
        "matrix-headers-form-content.subtable-column.criticalRoles.description": "Описание",
        "matrix-headers-form-content.subtable-column.criticalRoles.delete": " ",

        "matrix-headers-form-content.subtable-column.criticalProfiles.system": "Система",
        "matrix-headers-form-content.subtable-column.criticalProfiles.criticalProfile": "Имя профиля",
        "matrix-headers-form-content.subtable-column.criticalProfiles.description": "Описание",
        "matrix-headers-form-content.subtable-column.criticalProfiles.delete": " ",

        "risk-view.btn-create-risk": "Добавить риск",
        "criticalRole-view.btn-create-criticalRole": "Добавить крит. роль",
        "criticalProfile-view.btn-create-criticalProfile": "Добавить крит. профиль",


        "delete-matrix-header-dialog.title": "Удаление матрицы",
        "delete-matrix-header-dialog.text": "Матрица {matrixHeaderId} будет удалена, Вы желаете удалить матрицу?",


        "users-list-page.toolbar.text.title": "Список пользователей",
        "users-list-page.toolbar.add-user-btn": "Добавить пользователя",

        "user-form-page-header.new.title": "Создание нового пользователя",
        "user-form-page-header.edit.title": "Редактирование пользователя",
        "user-form-page-header.read.title": "Просмотр пользователя",
        "user-form-page-content.username-label": "Введите логин пользователя",
        "user-form-page-content.password-label": "Введите пароль пользователя",
        "user-form-page-content.firstname-label": "Введите имя пользователя",
        "user-form-page-content.lastname-label": "Введите фамилию пользователя",
        "user-form-page-content.middlename-label": "Введите отчество пользователя",
        "user-form-page-content.phone-label": "Введите телефон пользователя",
        "user-form-page-content.email-label": "Введите E-mail пользователя",
        "user-form-page-content.role-label": "Выберите роль пользователя",
        "user-form-page.delete-dialog.title": "Удаление пользователя",
        "user-form-page.delete-dialog.text": 'Пользователь “{user}” будет удален, Вы желаете продолжить?',
        "user-form-page.change-password-btn": "Изменить пароль",
        "user-form-page.change-password-dialog.title": "Изменение пароля",
        "user-form-page.change-password-dialog.new-password-label": "Введите новый пароль",
        "user-form-page.change-password-dialog.confirm-password-label": "Подтвердите новый пароль",
        "user-form-page.change-password-dialog.new-password-error": "Пароль не может быть пустым",
        "user-form-page.change-password-dialog.confirm-password-error": "Введенные пароли не совпадают",

        "locks-page.toolbar.title": "Блокировки",
        "locks-page.object-type-column.title": "Тип объекта",
        "locks-page.id-column.title": "Id объекта",
        "locks-page.user-column.title": "Пользователь",

        "integration-planning.first-start-datetime.title": "Выберите дату первого запуска",
        "integration-planning.periodic-field.title": "Выберите периодичность",
        "integration-planning.custom-periodic-field.title": "Свой вариант периодичности",
        "integration-schedule-page.title.add": "Создание планирования синхронизации для {system}",
        "integration-schedule-page.title.edit": "Редактирование планирования синхронизации для {system}",
        "integration-schedule-page.title.read": "Просмотр планирования синхронизации для {system}",
        "integration-schedule-page-name.input-description": "Введите имя планирования",
        "integration-schedule-page.variant.title": "Выберите варианты синхронизации",
        "integration-schedule-page.variant.delta": "Загрузка дельты",
        "integration-schedule-page.entity.permission-details.title": "Детализация полномочий",
        "integration-schedule-page.entity.repository-objects.title": "Объекты репозитория",
        "integration-schedule-page.entity.profile.title": "Профили",
        "integration-schedule-page.entity.role.title": "Роли",
        "integration-schedule-page.entity.employee.title": "Пользователи",
        "integration-schedule-page.time.custom.hours.text": "В часах",
        "integration-schedule-page.time.custom.weeks.text": "В неделях",
        "integration-schedule-page.time.custom.months.text": "В месяцах",
        "integration-schedule-page.time.single.text": "Одиночный запуск",
        "integration-schedule-page.time.days.text": "Каждый день",
        "integration-schedule-page.time.months.text": "Каждый месяц",
        "integration-schedule-page.time.half-year.text": "Каждые полгода",
        "integration-schedule-page.time.custom.text": "Свой вариант",
        "integration-page.schedule-planning-dialog.title": "Запланированные синх. для {system}",
        "integration-page.history-dialog.title": "Предыдущие синх. для {system}",
        "integration-page.matrix-upload-dialog.title": "Загрузка матрицы для {system}",
        "integration-page.matrix-upload-dialog.field.functions": "Функции",
        "integration-page.matrix-upload-dialog.field.actions": "Операции",
        "integration-page.matrix-upload-dialog.field.permissions": "Разрешения",
        "integration-page.matrix-upload-dialog.field.risks": "Риски",
        "integration-page.matrix-upload-dialog.field.riskDescriptions": "Описания рисков",
        "integration-page.matrix-upload-dialog.field.matrices": "Матрицы",
        "integration-page.matrix-upload-dialog.field.riskToMatrices": "Риски матриц",
        "integration-page.matrix-upload-dialog.field.busynessProcesses": "Бизнес процессы",
        "integration-page.matrix-upload-dialog.field.functionsTobusynessProcesses": "Функции бизнес процессов",

        "error-page.navigate-to-home.title": "Перейти на главную",

        "forbidden-page.title": "403",
        "forbidden-page.subtitle": "В доступе отказано",
        "forbidden-page.description": "У вас нет прав на доступ к этой странице",

        "not-found-page.title": "404",
        "not-found-page.subtitle": "Страница не найдена",

        "report-columns-dialog.title": "Настройка колонок отчета",
        "report-columns-dialog.report-level.label": "Выберите уровень отчета",
        "report-columns-dialog.report-type.label": "Выберите тип отчета",
        "report-columns-dialog.submit.text": "Перейти к просмотру колонок",
        "report-columns-dialog.actions.reset": "Восстановить по умолчанию",
        "report-columns-dialog-list.checked-all-checkbox.label": "Выбрать все колонки",

        "report-modeling-box.accordion-title": "Моделирование полномочий",

        "report-modeling-header.save-title": "Сохранить изменения",

        "role-level-modeling-role-search-help.label": "Текущая роль",
        "role-level-modeling-role-search-help.dialog.columns.role": "Имя роли",
        "role-level-modeling-role-search-help.dialog.columns.system-id": "Система",
        "role-level-modeling-role-search-help.dialog.columns.description": "Описание",
        "role-level-modeling-role-search-help.dialog.columns.role-type": "Тип роли",
        "role-level-modeling-role-search-help.dialog.title": "Выбор роли",
        "role-level-modeling-header-back-button-text":"Вернуться к групповой роли",
        "role-level-modeling-header-current-role-text": "Моделируемая роль", 

        "role-level-modeling-changed-roles-select.label": "Измененные роли",

        "report-modeling-views-switch.pfcg": "Ракурс PFCG",
        "report-modeling-views-switch.permissions": "Ракурс полномочий",

        [`report-modeling-subviews-picker.${REPORT_MODELING_SUBVIEWS.MENU}`]: "Меню",
        [`report-modeling-subviews-picker.${REPORT_MODELING_SUBVIEWS.PERMISSIONS}`]: "Полномочия",

        "report-modeling-views-actions.select": "Выбор",
        "report-modeling-views-actions.manual-select": "Выбор вручную",
        "report-modeling-views-actions.org-levels": "Организационные уровни",

        "pfcg-view.columns.node": "Узел",
        "pfcg-view.columns.status": "Статус ведения",
        "pfcg-view.columns.active": "Активно",
        "pfcg-view.columns.actions": "Операция",
        "pfcg-view.columns.value": "Значение",
        "pfcg-view.columns.description": "Текст",

        [`pfcg-view.statuses.${REPORT_MODELING_STATUSES.CHANGED}`]: "Изменено",
        [`pfcg-view.statuses.${REPORT_MODELING_STATUSES.SET}`]: "Задано",
        [`pfcg-view.statuses.${REPORT_MODELING_STATUSES.STANDARD}`]: "Стандарт",
        [`pfcg-view.statuses.${REPORT_MODELING_STATUSES.MANUAL}`]: "Вручную",

        "pfcg-view.node.class": "Класс объект {objectId}",
        "pfcg-view.node.permission_object": "Объект полномочий {objectId}",
        "pfcg-view.node.permission": "Полномочия {objectId}",

        "report-modeling-edit-dialog.title.select": "Определение значений",
        "report-modeling-edit-dialog.title.range": "Значение полей",
        "report-modeling-edit-dialog.permission-object.label": "Объект",
        "report-modeling-edit-dialog.field.label": "Имя поля",
        "report-modeling-edit-dialog.select-table.columns.key": "Операция",
        "report-modeling-edit-dialog.select-table.columns.text": "Текст",
        "report-modeling-edit-dialog.select-table.title": "Все полномочия - Операции",
        "report-modeling-edit-dialog.range-table.columns.from": "Со значения",
        "report-modeling-edit-dialog.range-table.columns.to": "По значение",
        "report-modeling-edit-dialog.range-table.columns.text": "Текст",
        "report-modeling-edit-dialog.range-table.toolbar.title": "Все полномочия - Интервальные значения",
        "report-modeling-edit-dialog.range-table.toolbar.add": "Добавить интервал",

        "select-permissions-dialog.title": "Изменение роли: вставка полномочий",
        "select-permissions-dialog.actions.apply": "Вставить выбранные",
        "select-permissions-dialog.actions.cancel": "Отменить выбор",
        "select-permissions-dialog.table.title": "Выберите подлежащие вставке полномочия",

        "manual-select-permissions-dialog.title": "Выбор полномочий вручную",
        "manual-select-permissions-dialog.columns.key": "Полномочия",
        "manual-select-permissions-dialog.columns.text": "Описания",

        "org-levels-dialog.title": "Определение организационных уровней",
        "org-levels-dialog.actions.apply": "Применить уровни",
        "org-levels-dialog.table.title": "Выполните ведение значений для организационных уровней роли",
        "org-levels-dialog.table.columns.description": "Орг. Уровень",
        "org-levels-dialog.table.columns.from": "Со значения",
        "org-levels-dialog.table.columns.to": "По значение",

        "user-level-modeling-user-search-help.label": "Текущий пользователь",
        "user-level-modeling-user-search-help.dialog.title": "Выбор пользователя",
        "user-level-modeling-user-search-help.dialog.columns.user": "Пользователь",
        "user-level-modeling-user-search-help.dialog.columns.system-id": "Система",
        "user-level-modeling-user-search-help.dialog.columns.description": "Описание",
        "user-level-modeling.user-search-help-dialog.submit": "Выбрать пользователя",
        "user-level-modeling.user-search-help-dialog.selected-user": "Выбранный пользователь",
        "user-level-modeling.role-search-help-dialog.title": "Выбор роли",
        "user-level-modeling.role-search-help-dialog.select": "Выбрать роль",
        "user-level-modeling.role-search-help-dialog.selected-role": "Выбранная роль",
        "user-level-modeling.role-search-help-dialog.columns.role": "Имя роли",
        "user-level-modeling.role-search-help-dialog.columns.description": "Описание",
        "user-level-modeling-role-search-help.dialog.columns.role-type": "Тип роли",
        "user-level-modeling.role-search-help-dialog.toolbar.add": "Добавить роль",
        "user-level-modeling.role-search-help-dialog.toolbar.create": "Создать новую роль",
        "user-level-modeling.changed-users-select.label": "Измененные пользователи",
        "user-level-modeling.role-exists-error": "Роль с данным ID уже существует",
        "user-level-modeling.role-creation-error": "Ошибка создания роли",
        "user-level-modeling.role-doesnt-exist-error": "Роли с данным ID не существует",
        "user-level-modeling.user-has-role-error": "Пользователю уже присвоена данная роль",

        "create-new-role-dialog.title": "Создание новой роли",
        "create-new-role-dialog.submit": "Создать роль",
        "create-new-role-dialog.role-input.label": "Роль",
        "create-new-role-dialog.description-input.label": "Описание роли",
        "create-new-role-dialog.role-type-input.label": "Тип роли",
        "create-new-role-dialog.role-type-single.description": "Одиночная",
        "create-new-role-dialog.role-type-group.description": "Групповая",

        "add-role-dialog.title": "Добавление роли",
        "add-role-dialog.columns.key": "Роль",
        "add-role-dialog.columns.text": "Описание роли",
        "add-role-dialog.tabs.persisted-roles": "Существующие роли",
        "add-role-dialog.tabs.new-roles": "Созданные роли",

        "role-level-modeling.role-search-help-dialog.submit": "Выбрать роль",
        "role-level-modeling.role-search-help-dialog.selected-role": "Выбранная роль",

        "permission-without-class-error-dialog.text": "У следующих полномочий не задан класс:",
        "permission-without-class-error-dialog.remove-unvalid": "Убрать полномочия с ошибкой",

        "report-columns-dialog.success.title": "Сохранение",
        "report-columns-dialog.success.content": "Колонки успешно сохранены",

        "role-menu.select-role-dialog.title": "Выбор роли",

        "role-menu.copy-menu-dialog.title": "Выбор транзакций из меню",
        "role-menu.copy-menu-dialog.submit": "Вставить выбранные",
        "role-menu.copy-menu-dialog.cancel": "Отменить выбор",

        "role-menu.add-node-dialog.title": "Добавить транзакцию",

        "role-menu.add-folder-dialog.title": "Добавить папку",
        "role-menu.add-folder-dialog.folder-label": "Введите название папки",

        "custom-role-list-page.toolbar.text.title": "Список ролей",
        "custom-role-list-page.toolbar.btn-create-role": "Создать роль",
        "custom-role-form-page-header.new.title": "Добавление роли",
        "custom-role-form-page-header.edit.title": "Редактирование роли {customRoleId}",
        "custom-role-form-page-header.read.title": "Просмотр роли {customRoleId}",
        "custom-role-form-page-role.input-role-id":"Введите идентификатор роли",
        "custom-role-form-page-role.input-description":"Введите описание роли",
        "custom-role-form-page-role.input-system": "Выберите систему или группу систем",
        "custom-role-form-page-role.role-type-input.label":"Тип роли",
        "custom-role-form-page-role.role-type-input-single":"Одиночная", 
        "custom-role-form-page-role.role-type-input-group":"Групповая", 
        "custom-role-form-page.delete-dialog.title": "Удаление функции",
        "custom-role-form-page.delete-custom-role.text": `Роль {name} будет удалена. Желаете продолжить?`,
        

        "role-menu.add-node-dialog.column-transaction.text": "Транзакция",
        "role-menu.add-node-dialog.column-description.text": "Описание",

        [`conflict-levels-picker.${REPORT_MODELING_CONFLICT_LEVELS.ROLE}`]: "Конфликты внутри роли",
        [`conflict-levels-picker.${REPORT_MODELING_CONFLICT_LEVELS.GROUP_ROLE}`]: "Конфликты внутри групповой роли",
        [`conflict-levels-picker.${REPORT_MODELING_CONFLICT_LEVELS.USERNAME}`]: "Конфликты внутри пользователя",

        [`conflict-levels-picker.${REPORT_MODELING_CONFLICT_LEVELS_USER.USER}`]: "Конфликты внутри пользователя",
        [`conflict-levels-picker.${REPORT_MODELING_CONFLICT_LEVELS_USER.USER_ALL}`]: "Конфликты внутри всех пользователей",
        "summary-report.header.title": "Сводный отчет",
        "summary-report.header.description": "Последнее обновление: {date}",
        "summary-report.header.error": "Ошибка рассчета",
        "summary-report.dialog.schedule.title": "Настройка фонового расчета для сводного отчета",
        "summary-report.dialog.schedule.time-picker.descr": "Расчет в ",
        "summary-report.dialog.schedule.add-time-item.title": "Добавить время расчета",
        "summary-report.dialog.schedule.time.every-day.text": "Каждый день",
        "summary-report.dialog.schedule.time.in-2-days.text": "Раз в 2 дня",
        "summary-report.dialog.schedule.time.in-3-days.text": "Раз в 3 дня",
        "summary-report.dialog.schedule.time.in-1-week.text": "Раз в неделю",
        "summary-report.detailed.button.back.title": "Назад к сводному отчету",
        "summary-report.detailed.tags.all-tag.text": "Все пользователи",
        "summary-report.dialog.compare-reports.title": "Выберите даты для сравнительного отчета",
        "summary-report.dialog.compare-reports.error.date": "Некорректная дата",
        ///
        "busyness-processes-list-page.toolbar.text.title": "Список бизнесс процессов",
        "busyness-processes-list-page.toolbar.btn-create-busyness-process": "Добавить бизнесс процесс",

        "empty-busyness-processes-page.text": "На данный момент у вас нет ни одного бизнесс процесса.",
        
        "busyness-processes-list-page.btn-create-busyness-process": "Создать бизнесс процесс",


        "busyness-process-form-page-header.new.title": "Добавление бизнес процесса",
        "busyness-process-form-page-header.edit.title": "Редактирование бизнес процесса {busynessProcessId}",
        "busyness-process-form-page-header.read.title": "Просмотр бизнес процесса {busynessProcessId}",
        "busyness-process-form-page-header.btn-delete-busyness-process": "Удалить бизнес процесс",

        "busyness-process-form-page-busyness-process.title": "Ведение бизнес процесса",
        "busyness-process-form-page-busyness-process.input-busyness-process-id": "Введите идентификатор бизнес процесса",
        "busyness-process-form-page-busyness-process.input-description": "Описание",

        "delete-busyness-process-dialog.title": "Удаление бизнес процесса",
        "delete-busyness-process-dialog.text": "Система {name} будет удалена. Желаете продолжить?",
    }
}
