import React from "react"
import { produce } from "immer"
import RoleModelingBox from "../../role-modeling-box"
import { openRoleMenuNode, addRoleMenuFolder, addRoleMenuNode, getTransactionsByIds, removeRoleMenuNode, copyRoleMenu, getPfcgTreeForTcodes, getDefaultPermissionsValuesForTransactions, getTcodesMapCountFromMenu, getDeletedTcodesFromMenu } from "../../../../utils/role-menu"
import { addItemToRoleTree, modifyItemFieldInRoleTree, removeItemFromRoleTree, updateRoleTreeOrgLevels, getDeletedDefaultValuesForTcodes, substractPfcgTreeFromRole,  } from "../../../../utils/reports-modeling-utils"
import { findItemInTree, TreeWalker } from "../../../../utils/tree-table.js";
import PropTypes from "prop-types";

const SingleRoleView = ({ form, setForm }) => {

    const handleAddTreeItems = (items) => {
        const treeChanges = produce({ tree: form.tree, orgLevels: form.orgLevels, delta: form.delta, orgLevelsDelta: form.orgLevelsDelta, tcodeToValue: form.tcodeToValue },
            ({ tree, delta, orgLevels, orgLevelsDelta }) => {
                for (const item of items)
                    addItemToRoleTree(form.profile, orgLevels, tree, item, null, 0, [], delta, orgLevelsDelta, {}, true)
            });
        setForm({ ...form, tree: treeChanges.tree, orgLevels: treeChanges.orgLevels, delta: treeChanges.delta, orgLevelsDelta: treeChanges.orgLevelsDelta, tcodeToValue: treeChanges.tcodeToValue });
    }

    const handleSetOrgLevelValues = (values) => {
        const changes = produce(
            { orgLevels: form.orgLevels, orgLevelsDelta: form.orgLevelsDelta, tree: form.tree },
            ({ orgLevels, orgLevelsDelta, tree }) => updateRoleTreeOrgLevels(orgLevels, values, orgLevelsDelta, tree)
        );
        setForm({ ...form, orgLevels: changes.orgLevels, orgLevelsDelta: changes.orgLevelsDelta, tree: changes.tree, status: changes.status })
    }

    const handleTreeItemFieldChange = (itemPath, field, value) => {
        const changes = produce(
            { tree: form.tree, delta: form.delta, orgLevels:form.orgLevels },
            ({ tree, delta, orgLevels }) => modifyItemFieldInRoleTree(
                orgLevels, tree, itemPath, field, value, delta
            )
        );

        setForm({ ...form, tree: changes.tree, delta: changes.delta, orgLevels:changes.orgLevels,  status: changes.status });

    }

    const handleOpenRoleMenuNode = (itemPath) => {
        const changes = produce({ menu: form.menu }, ({ menu }) => { openRoleMenuNode(itemPath, menu) });
        setForm({ ...form, menu: changes.menu });
    }

    const handleAddRoleMenuFolder = (activeNodePath, folderName) => {
        const changes = produce({ menu: form.menu }, ({ menu }) => { addRoleMenuFolder(activeNodePath, menu, folderName) });
        setForm({ ...form, menu: changes.menu });
    }

    const handleAddRoleMenuNode = async (activeNodePath, tcodes) => {
        const transaction = await getTransactionsByIds(form.systemId, tcodes);
        const newPermissionsTree = await getPfcgTreeForTcodes(form.systemId, tcodes);
        const defaultValues = await getDefaultPermissionsValuesForTransactions(form.systemId, tcodes);

        const menuChanges = produce({ menu: form.menu }, ({ menu }) => { addRoleMenuNode(activeNodePath, menu, tcodes, transaction) });

        const treeChanges = produce({ tree: form.tree, orgLevels: form.orgLevels, delta: form.delta, orgLevelsDelta: form.orgLevelsDelta, tcodeToValue: form.tcodeToValue },
            ({ tree, delta, orgLevels, orgLevelsDelta, tcodeToValue }) => {
                for (const item of newPermissionsTree) {
                    addItemToRoleTree(form.profile, orgLevels, tree, item, null, 0, defaultValues, delta, orgLevelsDelta, {}, true);
                }
                if (tcodes) {
                    tcodes.forEach(tcode => {
                        tcodeToValue[tcode] = defaultValues.filter(value => value.permissionGroup === tcode)
                    })
                }
            });

        setForm({ ...form, menu: menuChanges.menu, tree: treeChanges.tree, orgLevels: treeChanges.orgLevels, delta: treeChanges.delta, orgLevelsDelta: treeChanges.orgLevelsDelta, tcodeToValue: treeChanges.tcodeToValue });
    }
    const handleRemoveRoleMenuNode = (activeNodePath) => {

        const removedMenuItem = findItemInTree(activeNodePath, form.menu)
        const tcodesMapItem = getTcodesMapCountFromMenu([removedMenuItem])

        const menuChanges = produce({ menu: form.menu }, ({ menu }) => { removeRoleMenuNode(activeNodePath, menu) });

        const tcodesMapMenu = getTcodesMapCountFromMenu(form.menu)
        const { deletedTcodes, remainedTcodes } = getDeletedTcodesFromMenu(tcodesMapItem, tcodesMapMenu)

        let updatedForm = { ...form, menu: menuChanges.menu };

        if (form.tree.length) {
            const { orgLevelsForDelete, profilesForDelete } = getDeletedDefaultValuesForTcodes(deletedTcodes, remainedTcodes, form.tcodeToValue)
            const treeChanges = produce({ tree: form.tree, delta: form.delta, orgLevels: form.orgLevels, orgLevelsDelta: form.orgLevelsDelta },
                ({ tree, delta, orgLevels, orgLevelsDelta }) => { 
                    substractPfcgTreeFromRole(tree, delta, orgLevels, orgLevelsDelta, orgLevelsForDelete, profilesForDelete, Array.from(deletedTcodes)) 
                });

            const newRoots = []

            treeChanges.tree.forEach(node => {
                if (node.children && node.children.length === 0) {
                    return
                }

                newRoots.push(node)
            })

            updatedForm = {
                ...updatedForm,
                tree: newRoots,
                orgLevels: treeChanges.orgLevels,
                delta: treeChanges.delta,
                orgLevelsDelta: treeChanges.orgLevelsDelta
            };
        }
        setForm(updatedForm);
    }

    const handleCopyRoleMenu = (checkedMenuItems) => {
        const changes = produce({ menu: form.menu }, ({ menu }) => { copyRoleMenu(checkedMenuItems, menu) });
        setForm({ ...form, menu: changes.menu });
    }

    const handleRemoveTreeItem = (path) => {
        const changes = produce({ tree: form.tree, delta: form.delta, orgLevels: form.orgLevels }, ({ tree, delta, orgLevels }) => { removeItemFromRoleTree(tree, path, delta, orgLevels) });
        setForm({ ...form, tree: changes.tree, delta: changes.delta, orgLevels: changes.orgLevels })
    }

    const handleExpandItemTree = (path) => {
        const changes = produce(({ tree: form.tree, path: path }), ({ tree, path }) => {
            const item = findItemInTree(path, tree)
            item.open = !item.open;
            const treeWalker = new TreeWalker(item.children);
            treeWalker.walk(
                node => {
                    node.open = item.open;
                },
                () => {
                    return {
                        keyField: "",
                        keyValue: "",
                        childField: "children"
                    }
                },
                () => {

                }
            )
        })
        setForm({ ...form, tree: changes.tree })
    }

    return (
        <RoleModelingBox
            role={form}
            onAddTreeItems={handleAddTreeItems}
            onSetOrgLevelValues={handleSetOrgLevelValues}
            onTreeItemFieldChange={handleTreeItemFieldChange}
            onOpenRoleMenuNode={handleOpenRoleMenuNode}
            onAddRoleMenuFolder={handleAddRoleMenuFolder}
            onAddRoleMenuNode={handleAddRoleMenuNode}
            onRemoveRoleMenuNode={handleRemoveRoleMenuNode}
            onCopyRoleMenu={handleCopyRoleMenu}
            onRemoveTreeItem={handleRemoveTreeItem}
            onExpandItemTree={handleExpandItemTree}
        />
    );
}

export default SingleRoleView;

SingleRoleView.propTypes = {
    form: PropTypes.object,
    setForm: PropTypes.func
}