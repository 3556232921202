import React from 'react';
import { useIntl } from 'react-intl';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

import { SelectInput, PhoneInput, TextInput } from '../../../common/form-controls';
import { MenuItem } from "../../../common/menu";
import { ADD_MODE, READ_MODE } from "../../../common/entity-form";
import useUserRoles from '../../../common/hooks/useUserRoles';

const UserRolesInput = ({ value, setValue, label, disabled, error }) => {
    const { userRoles, userRolesText } = useUserRoles();

    return(
        <SelectInput
            label={label}
            value={value}
            onChange={(event) => setValue(event.target.value)}
            disabled={disabled}
            error={error ? value === error.value: false}
            errorMessage={error && error.message}
        >
            {userRoles?.map(role => (
                <MenuItem
                    value={role.name}
                    key={role.id}
                >
                    {userRolesText[role.name]}
                </MenuItem>
            ))}
        </SelectInput>
    );
};

const UserFormContent = ({ form, setForm, mode, validationErrors }) => {
    const intl = useIntl();

    const setField = (key, value) => setForm({ ...form, [key]: value });

    const formDisabled = mode === READ_MODE;
    const idDisabled = mode !== ADD_MODE;
    const showPasswordInput = mode === ADD_MODE;

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                marginTop: "14px",
                maxWidth: "886px"
            }}
        >
            <TextInput
                label={intl.formatMessage({ id: "user-form-page-content.username-label" })}
                value={form.username}
                onChange={(event) => setField("username", event.target.value)}
                disabled={idDisabled}
                error={validationErrors?.username?.value === form.username}
                errorMessage={validationErrors?.username?.message}
            />

            {showPasswordInput && (
                <TextInput
                    type="password"
                    label={intl.formatMessage({ id: "user-form-page-content.password-label" })}
                    value={form.password}
                    onChange={(event) => setField("password", event.target.value)}
                    disabled={formDisabled}
                    error={validationErrors?.password?.value === form.password}
                    errorMessage={validationErrors?.password?.message}
                />
            )}

            <TextInput
                label={intl.formatMessage({ id: "user-form-page-content.firstname-label" })}
                value={form.firstName}
                onChange={(event) => setField("firstName", event.target.value)}
                disabled={formDisabled}
                error={validationErrors?.first_name?.value === form.firstName}
                errorMessage={validationErrors?.first_name?.message}
            />

            <TextInput
                label={intl.formatMessage({ id: "user-form-page-content.lastname-label" })}
                value={form.lastName}
                onChange={(event) => setField("lastName", event.target.value)}
                disabled={formDisabled}
                error={validationErrors?.last_name?.value === form.lastName}
                errorMessage={validationErrors?.last_name?.message}
            />

            <TextInput
                label={intl.formatMessage({ id: "user-form-page-content.middlename-label" })}
                value={form.middleName}
                onChange={(event) => setField("middleName", event.target.value)}
                disabled={formDisabled}
                error={validationErrors?.middle_name?.value === form.middleName}
                errorMessage={validationErrors?.middle_name?.message}
            />

            <PhoneInput
                value={form.phone}
                label={intl.formatMessage({ id: "user-form-page-content.phone-label" })}
                onChange={(value) => setField("phone", value)}
                disabled={formDisabled}
                error={validationErrors?.phone?.value ===  form.phone.split(' ').join('')}
                errorMessage={validationErrors?.phone?.message}
            />

            <TextInput
                label={intl.formatMessage({ id: "user-form-page-content.email-label" })}
                value={form.email}
                onChange={(event) => setField("email", event.target.value)}
                disabled={formDisabled}
                error={validationErrors?.email?.value === form.email}
                errorMessage={validationErrors?.email?.message}
            />

            <UserRolesInput
                value={form.role}
                setValue={(value) => setField("role", value)}
                label={intl.formatMessage({ id: "user-form-page-content.role-label" })}
                disabled={formDisabled}
                error={validationErrors?.role}
            />
        </Box>
    );
};

UserRolesInput.propTypes = {
    value: PropTypes.string,
    setValue: PropTypes.func,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.object
};

UserFormContent.propTypes = {
    form: PropTypes.shape({
        username: PropTypes.string,
        password: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        middleName: PropTypes.string,
        phone: PropTypes.string,
        email: PropTypes.string,
        role: PropTypes.string
    }),
    setForm: PropTypes.func,
    mode: PropTypes.string,
    validationErrors: PropTypes.shape({
        username: PropTypes.string,
        password: PropTypes.string,
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        middle_name: PropTypes.string,
        phone: PropTypes.string,
        email: PropTypes.string,
        role: PropTypes.string
    })
};

export default UserFormContent;
